class AssetGroup {
    constructor(name, assets) {
        this.name = name;
        this.assets = assets;
    }

    getName() {
        return this.name;
    }

    getAssets() {
        return this.assets;
    }

    getAsset(asset) {
        return this.assets.find((el) => el === asset);
    }

    getFirstAsset() {
        return this.assets[0];
    }
}

class Lightbox {
    constructor(
        container = document,
        lightboxSelector = '[data-lightbox]',
        assetSelector = '[data-lightbox-asset]',
        imageTemplateSelector = '[data-lightbox-image-template]',
        videoTemplateSelector = '[data-lightbox-video-template]',
        backdropSelector = '[data-lightbox-backdrop]',
        nextButtonSelector = '[data-lightbox-next]',
        previousButtonSelector = '[data-lightbox-previous]',
        openTriggerAttribute = 'data-lightbox-group-open',
        closeTriggerSelector = '[data-lightbox-close]',
        assetGroupAttribute = 'data-lightbox-group'
    ) {
        this.container = container;
        this.lightbox = this.container.querySelector(lightboxSelector);
        this.asset = this.lightbox.querySelector(assetSelector);
        this.imageTemplate = this.lightbox.querySelector(imageTemplateSelector);
        this.videoTemplate = this.lightbox.querySelector(videoTemplateSelector);
        this.backdrop = this.lightbox.querySelector(backdropSelector);
        this.nextButton = this.lightbox.querySelector(nextButtonSelector);
        this.previousButton = this.lightbox.querySelector(previousButtonSelector);

        if (
            !this.container ||
            !this.lightbox ||
            !this.asset ||
            !this.imageTemplate ||
            !this.videoTemplate ||
            !this.backdrop ||
            !this.nextButton ||
            !this.previousButton
        ) {
            return;
        }

        this.openTriggerAttribute = openTriggerAttribute;
        this.openTriggers = Array.from(this.container.querySelectorAll(`[${openTriggerAttribute}]`));
        this.closeTriggers = Array.from(this.container.querySelectorAll(closeTriggerSelector));

        this.assetGroupAttribute = assetGroupAttribute;
        this.assetGroups = [];
        this.activeAssetGroup = null;
        this.activeAsset = null;

        this._init();
    }

    _init() {
        this.openTriggers.forEach((trigger) => {
            trigger.addEventListener('click', (e) => {
                const groupName = trigger.getAttribute(this.openTriggerAttribute);
                const assetGroup = this._getAssetGroup(groupName);

                if (assetGroup) {
                    this.activeAssetGroup = assetGroup;
                } else {
                    const newAssetGroup = this._createAssetGroup(groupName);

                    this.activeAssetGroup = newAssetGroup;
                    this.assetGroups.push(newAssetGroup);
                }

                if (e.target.getAttribute(this.assetGroupAttribute)) {
                    this._showAsset(this.activeAssetGroup.getAsset(e.target), false);
                } else {
                    this._showAsset(this.activeAssetGroup.getFirstAsset(), false);
                }

                this._toggleButtons();
                this.open();
            });
        });

        this.closeTriggers.forEach((trigger) => {
            trigger.addEventListener('click', () => {
                this.close();
            });
        });

        this.nextButton.addEventListener('click', () => {
            this.next();
        });

        this.previousButton.addEventListener('click', () => {
            this.previous();
        });
    }

    open() {
        // Disable page scrolling while the lightbox is open
        document.body.classList.add('overflow-hidden');

        this.lightbox.classList.remove('hidden');
    }

    close() {
        // Enable page scrolling when the lightbox is closed
        document.body.classList.remove('overflow-hidden');

        this.lightbox.classList.add('hidden');
    }

    next() {
        const assets = this.activeAssetGroup.getAssets();
        const index = assets.indexOf(this.activeAsset);

        if (index === assets.length - 1) {
            this._showAsset(assets[0]);
        } else {
            this._showAsset(assets[index + 1]);
        }
    }

    previous() {
        const assets = this.activeAssetGroup.getAssets();
        const index = assets.indexOf(this.activeAsset);

        if (index === 0) {
            this._showAsset(assets[assets.length - 1]);
        } else {
            this._showAsset(assets[index - 1]);
        }
    }

    _getAssetGroup(groupName) {
        return this.assetGroups.find((assetGroup) => assetGroup.getName() === groupName);
    }

    _createAssetGroup(groupName) {
        const assets = Array.from(this.container.querySelectorAll(`[${this.assetGroupAttribute}="${groupName}"]`));
        return new AssetGroup(groupName, assets);
    }

    _showAsset(asset, animation = true) {
        const newAsset = this._createNewAsset(asset);

        if (animation) {
            this.asset.classList.add('opacity-0');

            const onAnimationEnd = () => {
                this.asset.parentNode.appendChild(newAsset);
                this.asset.parentNode.removeChild(this.asset);

                this.asset = newAsset;
                this.activeAsset = asset;

                /* eslint-disable */
                void this.asset.offsetWidth;
                /* eslint-enable */

                this.asset.classList.remove('opacity-0');

                this.asset.removeEventListener('transitionend', onAnimationEnd);
            };

            this.asset.addEventListener('transitionend', onAnimationEnd);
        } else {
            this.asset.parentNode.appendChild(newAsset);
            this.asset.parentNode.removeChild(this.asset);

            this.asset = newAsset;
            this.activeAsset = asset;
        }
    }

    _createNewAsset(asset) {
        if (this.constructor._getAssetType(asset) === 'video') {
            return this._createNewVideo(asset);
        }

        return this._createNewImage(asset);
    }

    _createNewImage(image) {
        const newImage = this.imageTemplate.content.firstElementChild.cloneNode(true);
        newImage.src = image.src;
        newImage.alt = image.alt;
        return newImage;
    }

    _createNewVideo(video) {
        const newVideo = this.videoTemplate.content.firstElementChild.cloneNode(true);
        newVideo.src = video.src;
        newVideo.width = video.width;
        newVideo.height = video.height;
        return newVideo;
    }

    _toggleButtons() {
        if (this.activeAssetGroup.getAssets().length <= 1) {
            this.nextButton.classList.add('hidden');
            this.previousButton.classList.add('hidden');
        } else {
            this.nextButton.classList.remove('hidden');
            this.previousButton.classList.remove('hidden');
        }
    }

    static _getAssetType(asset) {
        if (asset.tagName === 'IFRAME') {
            return 'video';
        }

        return 'image';
    }
}

const initLightbox = (container) => {
    new Lightbox(container);
};

export { initLightbox as default };
