import initAnimatedToggle from './animated-toggle';

const initSidebars = (
    containerAttribute = 'data-sidebar',
    toggleAttribute = 'data-sidebar-toggle',
    contentAttribute = 'data-sidebar-content',
    backdropAttribute = 'data-sidebar-backdrop'
) => {
    const containers = Array.from(document.querySelectorAll(`[${containerAttribute}]`));

    containers.forEach((container) => {
        const backdropSelector = `[${backdropAttribute}=${container.getAttribute(containerAttribute)}]`;
        const contentSelector = `[${contentAttribute}=${container.getAttribute(containerAttribute)}]`;
        const toggleSelector = `[${toggleAttribute}=${container.getAttribute(containerAttribute)}]`;

        // Animate navigation backdrop on click
        initAnimatedToggle(backdropSelector, toggleSelector, {
            animationClass: 'animate-nav-fade-in',
        });

        // Animate navigation content on click
        initAnimatedToggle(contentSelector, toggleSelector, {
            animationClass: 'animate-nav-slide-in',
            onOpen: () => {
                container.classList.remove('hidden');
            },
            afterClose: () => {
                container.classList.add('hidden');
            },
        });
    });
};

export { initSidebars as default };
