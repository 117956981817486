import { JustForShow, JustForShowPolyfills } from 'justforshow';

JustForShowPolyfills();

window.addEventListener('DOMContentLoaded', () => {
    new JustForShow('[data-jfs-from]', {
        preset: 'animate-from',
        rootMargin: '-48px 0px -48px 0px',
    });

    // Seperate JFS instance with a smaller rootMargin
    new JustForShow('[data-jfs-footer]', {
        preset: 'animate-from',
        rootMargin: '0px 0px 0px 0px',
    });

    // Removing the preloader once JustForShow is initialized.
    document.getElementById('preloader').classList.add('hidden');
});
