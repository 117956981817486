import gsap from 'gsap';

class Panels {
    constructor(container = document, panelAttribute = 'data-panel', panelTriggerAttribute = 'data-goto-panel') {
        this.container = container;
        this.panelAttribute = panelAttribute;
        this.panelTriggerAttribute = panelTriggerAttribute;

        this.panels = this.container.querySelectorAll(`[${this.panelAttribute}]`);
        this.panelTriggers = this.container.querySelectorAll(`[${this.panelTriggerAttribute}]`);

        this.init();
    }

    init() {
        this.panelTriggers.forEach((panelTrigger) => {
            panelTrigger.addEventListener('click', (e) => {
                e.preventDefault();

                const panelId = panelTrigger.getAttribute(this.panelTriggerAttribute);

                this._hideAllPanels();
                // this._removeAllTriggerHighlights();

                this._showPanel(panelId);
                // this.constructor._highlightTrigger(panelTrigger);
            });
        });
    }

    _showPanel(panelId) {
        const panel = this.container.querySelector(`[${this.panelAttribute}="${panelId}"]`);

        if (!panel) return;

        panel.classList.remove('hidden');

        gsap.from(Array.from(panel.querySelectorAll('[data-gsap-fsm-panel-item]')), {
            opacity: 0,
            x: '1rem',
            duration: 0.3,
            stagger: 0.03,
            ease: 'Expo.easeOut',
        });
    }

    _hideAllPanels() {
        this.panels.forEach((panel) => {
            panel.classList.add('hidden');
        });
    }

    // static _highlightTrigger(panelTrigger) {
    //     panelTrigger.classList.add('active');
    // }

    // _removeAllTriggerHighlights() {
    //     this.panelTriggers.forEach((panelTrigger) => {
    //         panelTrigger.classList.remove('active');
    //     });
    // }
}

const initPanels = (container = document) => {
    const sortingPanelsContainer = container.querySelector('[data-sorting-panels]');

    if (!sortingPanelsContainer) return;

    new Panels(sortingPanelsContainer);
};

export { initPanels as default };
