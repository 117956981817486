import './sentry';
import './jfs';

// Base scripts
import Alpine from 'alpinejs';
import GLightbox from 'glightbox';
import initCarousels from './carousel';
import initCookieAccept from './cookie-accept';
import initDropdowns from './dropdown';

// Plugin scripts
import initAsyncForms from '../../../app/Plugins/Form/resources/assets/js/main';
import initNotifications from '../../../app/Plugins/Notifications/resources/assets/js/notifications';

// Theme scripts
// import initMobileNavigation from './mobile-navigation';
import initSidebars from './sidebar';
import initPanels from './panels';
import initLightbox from './lightbox';

initCookieAccept();

window.addEventListener('DOMContentLoaded', () => {
    initCarousels();
    initSidebars();
    initDropdowns();
    initPanels();
    initLightbox();
    initAsyncForms();
    initNotifications();

    // Gallery lightbox
    GLightbox({
        selector: '[data-glightbox]',
        touchNavigation: true,
        loop: true,
        autoplayVideos: true,
    });

    // If we're using Livewire in this project, we don't need to initialize Alpine.js here.
    // Livewire will do this automatically. To make sure Alpine.js works everywhere, we can include the scripts in the
    // layout file. Docs: https://livewire.laravel.com/docs/installation#manually-including-livewires-frontend-assets
    Alpine.start();
});

// Tell Vite to build all files in this directory
import.meta.glob(['../img/**']);
